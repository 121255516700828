import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { BaseView } from '../classes/baseview';

@Injectable({
    providedIn: 'root',
})
export class CanDeactivateGuard implements CanDeactivate<BaseView> {
    canDeactivate(component: BaseView): boolean {
        if (component?.isStateChanged() && !component.isCompBusy()) {
            return confirm('You have unsaved changes! Do you really want to leave?');
        }
        return true;
    }
}
