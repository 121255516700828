import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { UserService } from '../core/service/user.service';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { PageService } from '../core/service/page.service';
import { MessageService } from 'primeng/api';
import { NavigationService } from '../core/service/navigate.service';
import { CacheService } from '../core/service/cache.service';
import { AuthServiceProxy } from '../../shared/service-proxies/service-proxies';
import { AnonymousGuardService, AuthGuardService } from '../core/guards/auth.guard';
import { RefCodeService } from '../core/service/refcode.service';
import { SweetAlertService } from '../core/service/sweet-alert.service';
import { AppUserService } from './services/appuserservice';

export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes),
  provideHttpClient(withFetch()),
  provideAnimations(),
    UserService,
    PageService,
    MessageService,
    NavigationService,
    CacheService,
    AuthServiceProxy,
    AuthGuardService,
    AnonymousGuardService,
    RefCodeService,
    SweetAlertService,
    AppUserService
  ]
};
