import { Routes } from '@angular/router';
import { AnonymousGuardService, AuthGuardService } from '../core/guards/auth.guard';
import { CanDeactivateGuard } from '../core/guards/can-deactivate.guard';

export const routes: Routes = [
    {
        path: 'ahsan',
        loadChildren: () => import('./views/test/ahsan/ahsan.routes').then(m => m.routes), canDeactivate: [CanDeactivateGuard]
    },
    {
        path: 'haroon',
        loadChildren: () => import('./views/test/haroon/haroon.routes').then(m => m.routes), canDeactivate: [CanDeactivateGuard]
    },
    {
        path: 'auth',
        loadChildren: () => import('./components/master-page1/master-page1-routing').then(m => m.authRoutes), canDeactivate: [CanDeactivateGuard], canActivate: [AnonymousGuardService]
    },
    {
        path: 'ashfaq',
        loadChildren: () => import('./views/test/ashfaq/ashfaq.routes').then(m => m.routes), canDeactivate: [CanDeactivateGuard]
    },
    {
        path: '',
        loadChildren: () => import('./components/master-page2/master-page2-routing').then(m => m.routes), canActivate: [AuthGuardService]
    },
    { path: '', redirectTo: '/auth', pathMatch: 'full' },
    { path: '**', pathMatch: 'full', redirectTo: 'auth/login' },
    { path: '', pathMatch: 'full', redirectTo: 'auth/login' }
];