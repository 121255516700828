import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { BlockuiComponent } from '../core/components/blockui/blockui.component';
import { BaseView } from '../core/classes/baseview';
import { MessagesModule } from 'primeng/messages';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule , ProgressBarModule , BlockuiComponent , MessagesModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends BaseView {
  override title = 'RemetricWeb';
}
