import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterOutlet } from '@angular/router';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { BlockuiComponent } from '../core/components/blockui/blockui.component';
import { BaseView } from '../core/classes/baseview';
import { MessagesModule } from 'primeng/messages';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, CommonModule, ProgressBarModule, BlockuiComponent, MessagesModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends BaseView {
  override title = 'RemetricWeb';
  isbusy: boolean = false;
  constructor(private router: Router) {
    super();
    this.addSubscription(this.router.events.subscribe(data => {
      if (data instanceof RouteConfigLoadStart) {
        this.getPageService().contentLoading();
        this.getPageService().setCompBusy('loading_application');
      } else if (data instanceof RouteConfigLoadEnd) {
        this.getPageService().contentLoaded();
        this.getPageService().setCompNotBusy();
      }
    }))
  }

  setBusy(isbusy: boolean) {
    this.isbusy = isbusy
  }

  isBusy() {
    return this.isbusy;
  }

}
